exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/Blog/BlogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-recensioner-fortum-js": () => import("./../../../src/pages/recensioner/fortum.js" /* webpackChunkName: "component---src-pages-recensioner-fortum-js" */),
  "component---src-pages-recensioner-greenely-js": () => import("./../../../src/pages/recensioner/greenely.js" /* webpackChunkName: "component---src-pages-recensioner-greenely-js" */),
  "component---src-pages-recensioner-js": () => import("./../../../src/pages/recensioner.js" /* webpackChunkName: "component---src-pages-recensioner-js" */),
  "component---src-pages-recensioner-vattenfall-js": () => import("./../../../src/pages/recensioner/vattenfall.js" /* webpackChunkName: "component---src-pages-recensioner-vattenfall-js" */)
}

